import { Box, useDisclosure } from "@chakra-ui/react";
import { isEqual } from "lodash";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { usePrevious } from "react-use";

import { AdminFlyout } from "adminComponents/molecules/Flyout";
import { PagedSearchResults } from "adminComponents/molecules/PagedSearchResults";
import { PracticeSetPreview } from "adminComponents/molecules/PracticeSetPreview";
import { GuestSearchResultsTab } from "adminComponents/organisms/LibrarySearchResultsTabs";
import { TeacherSignInModal } from "adminComponents/organisms/TeacherSignInModal";
import { GuestLibrarySearchResults } from "adminComponents/screens/GuestLibrarySearchResults";
import { AllSetsTab } from "adminComponents/screens/GuestLibrarySearchResults/components/AllSetsTab";
import { TeacherHomepageLibrary } from "adminComponents/screens/TeacherHomepageLibrary";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics, usePageTrack } from "lib/contexts/analytics";
import { IHandleSetQueryArgs } from "lib/hooks/useLibraryFilter";
import { usePageTitle } from "lib/hooks/usePageTitle";
import i18n from "lib/i18n";
import {
  COPPA_URL,
  LIBRARY_HOMEPAGE_SEARCH_PER_PAGE,
  PUBLIC_SEARCH_PER_PAGE,
  localStoreAuthKeyName,
} from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { useGeo } from "links/lib/features/geo";
import { useFetchPracticeSetItems } from "links/lib/features/practiceSetItems";
import { useFetchRecommendedPracticeSets } from "links/lib/features/practiceSets";
import { usePublicLibrarySearch } from "links/lib/features/search/usePublicLibrarySearch";
import { useFetchStandards } from "links/lib/features/standards";
import {
  AnalyticsEvent,
  AnalyticsPage,
  IPracticeSet,
  IPracticeSetType,
  PracticeSetAvailability,
  UserRole,
} from "links/lib/types";
import useFetchProviderRedirectUrl from "screens/SignIn/useFetchProviderRedirectUrl";
import { useGuestTeacherNavigationData } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";
import { useIsSingleParentSubjectSelected } from "screens/TeacherHome/hooks/useIsSingleParentSubjectSelected";
import { useInitialQuery } from "screens/TeacherPublicLibrary/LibraryRoot/hooks/useInitialQuery";
import { openSignInPopup } from "sessionComponents/utils/openSignInPopup";
import { useCreatePracticeSet } from "sharedComponents/hooks/useCreatePracticeSet";

const GuestTeacherHome: React.FC = () => {
  const { tab } = useParams<{ tab: string }>();
  const { authUser, isFeatureEnabled, refetchUser, setAuthToken } = useAuth();
  const history = useHistory();
  const { geo } = useGeo();
  const navigationData = useGuestTeacherNavigationData();
  const { trackEvent } = useAnalytics();
  const { t: tSharedLibraries } = useTranslation("admin", {
    keyPrefix: "sharedLibraries",
    useSuspense: false,
  });
  const { t: tSignUpModal } = useTranslation("admin", {
    keyPrefix: "teacherSignUpModal",
    useSuspense: false,
  });
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "teacherHomeContainer",
  });

  usePageTitle(t("pageTitle"));
  usePageTrack(AnalyticsPage.TeacherDashboard_Home);

  const fetchRecommendedPracticeSets = useFetchRecommendedPracticeSets({
    periodDays: 30,
    limit: 18,
  });
  useErrorToast(fetchRecommendedPracticeSets.error);

  const initialQuery = useInitialQuery({
    subjects: navigationData.subjects,
    authUser: authUser,
    useTeacherSubjectsAndGrades: false,
  });

  const [expertPage, setExpertPage] = useState(initialQuery.expertPage || 1);
  const [communityPage, setCommunityPage] = useState(
    initialQuery.communityPage || 1
  );

  const showPremium = !isFeatureEnabled("playtime.teacher.hide_premium");

  const region = authUser?.region || geo?.region_short || "";

  const {
    handleClearFilters,
    handleClearSearchTerm,
    handleOpenFilterFlyout,
    handleRemoveFilterTag,
    handleSetTerm,
    handleSuggest,
    handleChangeSubjects,
    handleChangeGradeLevels,
    filterFlyout,
    standardsModal,
    totalCounts,
    isInitialLoad,
    practiceSets,
    isSearchLoading,
    query,
    subjectCounts,
    suggestions,
    handleUpdateQuery,
    searchFilterSuggestions,
  } = usePublicLibrarySearch({
    gradeLevels: navigationData.gradeLevels,
    subjects: navigationData.subjects,
    region,
    country: authUser?.country || "US",
    expertPage,
    communityPage,
    perPage: PUBLIC_SEARCH_PER_PAGE,
    initialQuery,
    availability: PracticeSetAvailability.Public,
    showPremiumFilter: showPremium,
    distinguishExpertAndCommunity: true,
  });

  const fetchStandards = useFetchStandards({
    ids: query.filters.standardIds,
    offset: 0,
    limit: (query.filters.standardIds || []).length,
    enabled: (query.filters.standardIds || []).length > 0,
  });
  useErrorToast(fetchStandards.error);

  const prevQuery = usePrevious(query);
  useEffect(() => {
    if (typeof prevQuery !== "undefined" && !isEqual(query, prevQuery)) {
      setExpertPage(1);
      setCommunityPage(1);

      trackEvent(AnalyticsEvent.TeacherDashboard_PublicLibrary_Root_Search, {
        hasTerm: !!query.term,
        hasStandards: !!query.filters.standardIds.length,
        hasSubjects: !!query.filters.subjectIds.length,
        hasGrades: !!query.filters.gradeLevelIds.length,
        hasCertifiedOnly: query.filters.certifiedOnly,
        hasHidePremium: query.filters.hidePremium,
      });
    }
  }, [query, prevQuery, trackEvent]);

  const shouldRenderSearchResults =
    !!query.filters.standardIds.length ||
    !!query.filters.subjectIds.length ||
    !!query.filters.gradeLevelIds.length ||
    !!query.term;

  const params = useMemo(() => {
    return qs.stringify(
      {
        expertPage,
        communityPage,
        term: query.term,
        ...query.filters,
      },
      { encodeValuesOnly: true }
    );
  }, [expertPage, communityPage, query.filters, query.term]);

  useEffect(() => {
    if (shouldRenderSearchResults) {
      const path = !tab ? "/t/explore/all" : location.pathname;
      history.push(`${path}?${params}`);
    } else {
      history.push("/t/explore");
    }
  }, [history, params, shouldRenderSearchResults, tab]);

  const handleSearch = useCallback(
    (search: string) => {
      const params = qs.stringify(
        {
          term: search,
          page: 1,
          ...query.filters,
        },
        { encodeValuesOnly: true }
      );

      history.push(`/t/explore?${params}`);
    },
    [history, query]
  );

  const _handleChangeGradeLevels = (gradeLevelIds: string[]) => {
    const gradeLevelIdsSet = new Set(query.filters.gradeLevelIds);
    gradeLevelIds.forEach((gradeLevelId) => gradeLevelIdsSet.add(gradeLevelId));
    handleChangeGradeLevels(Array.from(gradeLevelIdsSet));
  };

  const _handleClearAllFilters = () => {
    handleClearFilters();
    history.push("/t/explore");
  };

  const handleGenerateSet = () => {
    history.push("/t/instant-sets");
  };

  const {
    isOpen: isSignUpModalOpen,
    onOpen: onOpenSignUpModal,
    onClose: onCloseSignUpModal,
  } = useDisclosure();
  const fetchProviderRedirectUrl = useFetchProviderRedirectUrl();
  const handleSignInClick = (provider: "Google" | "Clever" | "Microsoft") => {
    trackEvent(AnalyticsEvent.TeacherDashboard_Explore_Signup_Intent, {
      provider,
    });
    fetchProviderRedirectUrl.execute({
      provider,
      role: authUser?.role || UserRole.Teacher,
      language: i18n.language,
      popup: true,
    });
  };
  const prevRedirectUrl = usePrevious(
    fetchProviderRedirectUrl.data?.redirectUrl
  );
  useEffect(() => {
    if (
      fetchProviderRedirectUrl.data?.redirectUrl &&
      prevRedirectUrl !== fetchProviderRedirectUrl.data.redirectUrl
    ) {
      openSignInPopup({
        url: fetchProviderRedirectUrl.data.redirectUrl,
        onSignInSuccess: () => {
          const newToken = localStorage.getItem(localStoreAuthKeyName);
          if (!newToken) {
            console.error(
              "Successful sign in reported by popup, but new token not found in local storage."
            );
            return;
          }
          setAuthToken(newToken);
          refetchUser();
          setIsSuccessfulSignUp(true);
          setTimeout(() => {
            onCloseSignUpModal();
          }, 3500);
        },
      });
    }
  }, [
    prevRedirectUrl,
    fetchProviderRedirectUrl.data?.redirectUrl,
    isSignUpModalOpen,
    onCloseSignUpModal,
    refetchUser,
    setAuthToken,
  ]);

  const {
    isOpen: isPreviewSetFlyoutOpen,
    onOpen: onOpenPreviewSetFlyout,
    onClose: onClosePreviewSetFlyout,
  } = useDisclosure();
  const [searchResultsTab, setSearchResultsTab] = useState(
    GuestSearchResultsTab.All
  );
  const [previewSet, setPreviewSet] = useState<IPracticeSet>();
  const fetchPreviewSetItems = useFetchPracticeSetItems({
    practice_set_id: previewSet?.id || "",
  });
  useErrorToast(fetchPreviewSetItems.error);

  const previewSetFlyout = (
    <AdminFlyout
      title=""
      isOpen={isPreviewSetFlyoutOpen}
      onClose={onClosePreviewSetFlyout}
    >
      <PracticeSetPreview
        isGuest
        practiceSet={previewSet}
        items={fetchPreviewSetItems.data?.practice_set_items || []}
        handlePractice={onOpenSignUpModal}
        handleDetails={onOpenSignUpModal}
        handlePreview={onOpenSignUpModal}
      />
    </AdminFlyout>
  );

  const [isSuccessfulSignUp, setIsSuccessfulSignUp] = useState(false);
  const signUpModal = (
    <TeacherSignInModal
      title={tSignUpModal("title")}
      description={tSignUpModal("description")}
      hideImage={true}
      handleSignInWithCleverButtonClick={() => handleSignInClick("Clever")}
      handleSignInWithGoogleButtonClick={() => handleSignInClick("Google")}
      handleSignInWithMicrosoftButtonClick={() =>
        handleSignInClick("Microsoft")
      }
      handleBack={() => {
        // no-op
      }}
      isLoading={fetchProviderRedirectUrl.isLoading}
      coppaUrl={COPPA_URL}
      isOpen={isSignUpModalOpen}
      handleClose={onCloseSignUpModal}
      isSuccessfulSignUp={isSuccessfulSignUp}
    />
  );

  // The UI changes slightly if one parent subject is selected.
  // If either the parent subject is selected or all the selected
  // child subjects belong to the same parent, singleSelectedParentSubject
  // will be defined and equal to the parent subject.
  const singleSelectedParentSubject = useIsSingleParentSubjectSelected(
    query,
    navigationData.subjects,
    navigationData.parentSubjects
  );

  const { flyout: generateInstantSetFlyout } = useCreatePracticeSet({
    subjects: navigationData.subjects,
    gradeLevels: navigationData.gradeLevels,
    collections: [],
    practiceSetType: IPracticeSetType.Instant,
  });

  const onPracticeSetPreview = (set: IPracticeSet) => {
    setPreviewSet(set);
    onOpenPreviewSetFlyout();
  };

  const handleTabChange = useCallback(
    (tab: GuestSearchResultsTab) => {
      setSearchResultsTab(tab);
      switch (tab) {
        case GuestSearchResultsTab.All:
          history.push(`/t/explore/all?${params}`);
          break;
        case GuestSearchResultsTab.FromExperts:
          history.push(`/t/explore/experts?${params}`);
          break;
        case GuestSearchResultsTab.FromCommunity:
          history.push(`/t/explore/community?${params}`);
          break;
      }
    },
    [history, params]
  );

  const hideExpertTab =
    !isSearchLoading && totalCounts.practiceSets.expert === 0;

  const _handleUpdateQuery = ({
    term,
    gradeLevelIds,
    subjectIds,
  }: IHandleSetQueryArgs) => {
    const gradeLevelIdsSet = new Set(query.filters.gradeLevelIds);
    gradeLevelIds.forEach((gradeLevelId) => gradeLevelIdsSet.add(gradeLevelId));

    const subjectIdsSet = new Set(query.filters.subjectIds);
    subjectIds.forEach((subjectId) => subjectIdsSet.add(subjectId));

    handleUpdateQuery({
      term,
      gradeLevelIds: Array.from(gradeLevelIdsSet),
      subjectIds: Array.from(subjectIdsSet),
    });
  };

  if (shouldRenderSearchResults) {
    return (
      <>
        <GuestLibrarySearchResults
          isLoading={isInitialLoad}
          isSearching={isSearchLoading}
          navigationData={navigationData}
          selectedParentSubject={singleSelectedParentSubject}
          handleSelectSubjects={handleChangeSubjects}
          handleSelectGrades={_handleChangeGradeLevels}
          handleGenerateSet={handleGenerateSet}
          query={query}
          totalQueryCount={totalCounts.practiceSets.total}
          tab={searchResultsTab}
          handleTabChange={handleTabChange}
          handleRemoveFilterTag={handleRemoveFilterTag}
          handleClearAllFilters={_handleClearAllFilters}
          handleOpenFilterFlyout={handleOpenFilterFlyout}
          handleSearch={handleSearch}
          handleClearSearchInput={handleClearSearchTerm}
          handleSuggest={handleSuggest}
          suggestions={suggestions}
          handleSignUp={onOpenSignUpModal}
          standards={fetchStandards.data?.standards || []}
          hideExpertTab={hideExpertTab}
          searchFilterSuggestions={searchFilterSuggestions}
          handleUpdateQuery={_handleUpdateQuery}
        >
          <Box my={pxToRem(32)}>
            <Switch>
              <Route path="/t/explore/all">
                <AllSetsTab
                  isLoading={isSearchLoading}
                  handleTabChange={(tab) =>
                    handleTabChange(tab as GuestSearchResultsTab)
                  }
                  setsFromExperts={practiceSets.expert}
                  setsFromExpertsCount={totalCounts.practiceSets.expert}
                  setsFromCommunity={practiceSets.community}
                  setsFromCommunityCount={totalCounts.practiceSets.community}
                  onPracticeSetPreview={onPracticeSetPreview}
                  hideExpertTab={hideExpertTab}
                />
              </Route>
              <Route path="/t/explore/experts">
                <PagedSearchResults
                  practiceSets={practiceSets.expert}
                  isLoading={isSearchLoading}
                  page={expertPage}
                  perPage={LIBRARY_HOMEPAGE_SEARCH_PER_PAGE}
                  totalCount={totalCounts.practiceSets.expert}
                  handleSetPage={setExpertPage}
                  onPracticeSetPreview={onPracticeSetPreview}
                  emptyStateTitle={tSharedLibraries("emptyTitlePublic")}
                />
              </Route>
              <Route path="/t/explore/community">
                <PagedSearchResults
                  practiceSets={practiceSets.community}
                  isLoading={isSearchLoading}
                  page={communityPage}
                  perPage={LIBRARY_HOMEPAGE_SEARCH_PER_PAGE}
                  totalCount={totalCounts.practiceSets.community}
                  handleSetPage={setCommunityPage}
                  onPracticeSetPreview={onPracticeSetPreview}
                  emptyStateTitle={tSharedLibraries("emptyTitlePublic")}
                />
              </Route>
            </Switch>
          </Box>
        </GuestLibrarySearchResults>
        {filterFlyout}
        {standardsModal}
        {previewSetFlyout}
        {generateInstantSetFlyout}
        {signUpModal}
      </>
    );
  }

  return (
    <>
      <TeacherHomepageLibrary
        isGuest
        navigationData={navigationData}
        subjectCounts={subjectCounts}
        // TODO: Is this a good definition of "popular practice sets?"
        popularPracticeSets={
          fetchRecommendedPracticeSets.data?.practice_sets || []
        }
        query={query}
        suggestions={suggestions}
        isLoading={isInitialLoad}
        isSearching={isSearchLoading}
        handleSearch={handleSearch}
        handleSuggest={handleSuggest}
        handleSetSearchTerm={handleSetTerm}
        handleClearSearchInput={handleClearSearchTerm}
        handleSelectSubjects={handleChangeSubjects}
        handleSelectGrades={_handleChangeGradeLevels}
        handleGenerateSet={handleGenerateSet}
        handleOpenFilterFlyout={handleOpenFilterFlyout}
        handleSignUp={onOpenSignUpModal}
        handlePracticeSetPreview={onPracticeSetPreview}
        searchFilterSuggestions={searchFilterSuggestions}
        handleUpdateQuery={_handleUpdateQuery}
      />
      {filterFlyout}
      {standardsModal}
      {previewSetFlyout}
      {generateInstantSetFlyout}
      {signUpModal}
    </>
  );
};

export default GuestTeacherHome;
